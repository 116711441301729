/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import('./src/styles/styles.scss');
import React from 'react';

import {
    FilterLabelsCat,
    FilterLabelsDog,
} from './src/components/molecules/BreedFilters/breedModel';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { isGeneralPageWithWebform } from './src/components/utils/generalPageHelpers';

export const wrapPageElement = ({ element, props }) => {
    const hasWebform = isGeneralPageWithWebform(props.data);
    if (hasWebform) {
        return (
            <GoogleReCaptchaProvider
                key='recaptcha_b'
                useEnterprise={true}
                reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
            >
                {element}
            </GoogleReCaptchaProvider>
        );
    } else {
        return element;
    }
};

//a function that checks if the search param string contains a specific key
const hasKey = (search, key) => {
    const params = new URLSearchParams(search);
    return params?.has(key);
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
    const { search } = location;

    const catValues = Object.values(FilterLabelsCat);
    const dogValues = Object.values(FilterLabelsDog);
    const allBreedFilterKeys = [...catValues, ...dogValues];
    const uniqueBreedFilterKeys = [...new Set(allBreedFilterKeys)];

    // Check and see if we're paginating through results, or using filters, then dont scroll to top
    if (hasKey(search, 'page') || uniqueBreedFilterKeys.some((key) => hasKey(search, key))) {
        return false;
    } else {
        window.scrollTo(0, 0);
    }
};

// updating route update function to push the page view event
export const onRouteUpdate = ({ location }) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'page_view',
        event_params: {
            page_path: location.pathname,
        },
    });
};
